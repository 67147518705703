/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import cx from "classnames"
import Search from "../search"
import config, { t } from "../../../../config"
import MenuAct from "../../../redux/actions/MenuActions"
import {
    addCookie, readCookie, isSafari, getuserAgent, _isEmpty,
} from "../../tool/util"
import HeadSEOTags from "../HeadSEOTags"
// Style section
import Style from "./index.less"
import StylesSprite from "../../../assets/less/sprite.less"
import MobileLogo from "../../../public/img/mobile-logo.png"
import AppIcon from "../../../public/img/app-flat-icon.svg"

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showHeaderSearch: false,
            promoCodeRibbon: false,
            hideAppDownloadBanner: true,
        }
        this.PromoCodeRibbonElm = null
    }

    componentDidMount() {
        this.checkPromoCodeRibbon(this.props)
        this.checkAppDownloadBanner()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.checkPromoCodeRibbon(nextProps)
        this.checkAppDownloadBanner()
    }

    componentDidUpdate() {
        if (this.state.promoCodeRibbon) {
            // console.log("adding listener")
            this.PromoCodeRibbonElm = document.getElementById("close_promo_ribbon")
            if (this.PromoCodeRibbonElm) {
                this.PromoCodeRibbonElm.addEventListener("click", this.closePromoCodeRibbon, false)
            }
        }
    }

    componentWillUnmount() {
        if (this.PromoCodeRibbonElm) {
            this.PromoCodeRibbonElm.removeEventListener("click", this.closePromoCodeRibbon)
        }
    }

    getCartCountHtml = () => {
        let cartSize = this.props.cartSize ? this.props.cartSize : 0
        if (!_isEmpty(this.props.getProfileData)) {
            cartSize = this.props.getProfileData.cart_size
        }
        return cartSize > 0 ? (<span>{cartSize}</span>) : null
    }

    checkPromoCodeRibbon = nextProps => {
        if (
            typeof nextProps.initData !== "undefined"
            && typeof nextProps.initData.configurations !== "undefined"
            && typeof nextProps.initData.configurations.promocode_ribbon !== "undefined"
            && (
                typeof nextProps.initData.configurations.promocode_ribbon.isDisplay !== "undefined"
                && nextProps.initData.configurations.promocode_ribbon.isDisplay !== "false"
            )
            && typeof nextProps.initData.configurations.promocode_ribbon.taglineMobile !== "undefined"
        ) {
            const startTime = new Date(nextProps.initData.configurations.promocode_ribbon.startDate)
                .getTime()
            const endTime = new Date(nextProps.initData.configurations.promocode_ribbon.endDate)
                .getTime()
            const currentTime = (new Date()).getTime()
            if (currentTime > startTime && currentTime < endTime) {
                const isSetInCookie = readCookie("isClosedPromoRibbon")
                if (isSetInCookie && parseInt(isSetInCookie, 10) === 1) {
                    this.setState({ promoCodeRibbon: false })
                } else {
                    this.setState({
                        promoCodeRibbon:
                            nextProps.initData.configurations.promocode_ribbon.taglineMobile,
                    })
                }
            }
        }
    }

    closePromoCodeRibbon = () => {
        addCookie("isClosedPromoRibbon", 1, 1 * 60 * 60 * 1000)
        this.setState({ promoCodeRibbon: false })
    }

    closeAppDownloadBanner = () => {
        addCookie("hideAppDownloadBanner", 1, 7 * 24 * 60 * 60 * 1000)
        this.setState({ hideAppDownloadBanner: true })
    }

    checkAppDownloadBanner = () => {
        // hide banner for safari on ios
        // use the native banner instead from html meta tag
        if (getuserAgent().toLowerCase() === "ios" && isSafari()) {
            this.setState({
                hideAppDownloadBanner: true,
            })
            return
        }

        const isSetInCookie = readCookie("hideAppDownloadBanner")
        if (isSetInCookie && parseInt(isSetInCookie, 10) === 1) {
            this.setState({
                hideAppDownloadBanner: true,
            })
        } else {
            this.setState({
                hideAppDownloadBanner: false,
            })
        }
    }

    headerSearchToggle= () => {
        this.setState(state => ({ showHeaderSearch: !state.showHeaderSearch }))
    }

    showToogle= () => {
        this.props.toggleMenu(!this.props.isMenuHidden)
    }

    render() {
        let headerClass = ""
        if (this.props.isTransparent) headerClass = Style.product_header
        return (
            <div>
                <HeadSEOTags {...this.props.meta} />
                {
                    this.state.promoCodeRibbon
                    && (
                        <div className="promocoderibbon">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: this.state.promoCodeRibbon,
                                }}
                            />
                        </div>
                    )
                }
                <div>
                    {" "}
                    {/* this div is required. Otherwise dev mode breaks on banner close. */}
                    {
                        !this.state.hideAppDownloadBanner
                        && !config.isApplication
                        && this.props.isDisplayRibbon
                        && (
                            <div className={Style.appBanner}>
                                <button
                                    type="button"
                                    onClick={() => {
                                        this.closeAppDownloadBanner()
                                    }}
                                >
                                X
                                </button>
                                <div className="logo_img_top_strip">
                                    <img src={AppIcon} alt="" />
                                </div>
                                <div className="bluetext">
                                    <span>{t("Tours4fun App")}</span>
                                    <p>{t("Plan & Book Your Perfect Trip!")}</p>
                                </div>
                                <Link
                                    to="/app-download"
                                    target="_blank"
                                >
                                    {t("Free Download")}
                                </Link>
                            </div>
                        )
                    }
                </div>
                <div
                    className={cx(Style.infoHeader, {
                        "dis-none": !this.props.isDisplayRibbon,
                    })}
                >
                    <span className={Style.infoTextLeft}>{t("Biggest Selection")}</span>
                    <span className={Style.infoTextCenter}>{t("Best Price")}</span>
                    <span className={Style.infoTextRight}>24/7</span>
                </div>
                <header className={headerClass}>
                    <div className="container">
                        <button
                            type="button"
                            onClick={
                                () => {
                                    const { history } = this.props
                                    if (!window.lastLocation || history.action === "REPLACE") {
                                        history.push("/")
                                    } else {
                                        history.goBack()
                                    }
                                }
                            }
                            className={
                                cx(`${StylesSprite.sprite_base} ${Style.left_arrow}`, { "dis-none": !this.props.isBackButton })
                            }
                        />
                        <nav
                            className={(this.props.menuWithArrow) ? Style.list_page_nav : ""}
                            onClick={this.showToogle}
                        >
                            <div className={Style.stripmain}>
                                <div className={Style.strip_bar} />
                                {/* <span className={Style.countertext}>3</span> */}
                            </div>
                        </nav>
                        {
                            this.props.history.location.pathname !== "/shopping_cart"
                                ? (
                                    <div
                                        className={cx(Style.header_cart, { "dis-none": !this.props.isCart })}
                                    >
                                        <Link to="/shopping_cart">
                                            <em className={`${StylesSprite.sprite_base} ${Style.sprite_icon_cart}`} />
                                            { this.getCartCountHtml() }
                                        </Link>
                                    </div>
                                )
                                : (
                                    <div
                                        className={cx(Style.header_cart, { "dis-none": !this.props.isCart })}
                                    >
                                        <em className={`${StylesSprite.sprite_base} ${Style.sprite_icon_cart}`} />
                                        { this.getCartCountHtml() }
                                    </div>
                                )
                        }
                        <div
                            onClick={this.headerSearchToggle}
                            className={cx(Style.header_search, { "dis-none": !this.props.isSearch })}
                        >
                            <em className={`${StylesSprite.sprite_base} ${Style.sprite_blue_search}`} />
                        </div>
                        <div className={Style.logo}>
                            <Link to="/"><img src={MobileLogo} alt="" title="" /></Link>
                        </div>
                    </div>
                </header>
                {
                    this.state.showHeaderSearch
                    && (
                        <Search
                            isFromHeader
                            cancelAction={() => this.setState({ showHeaderSearch: false })}
                            history={this.props.history}
                        />
                    )
                }
            </div>
        )
    }
}

Header.propTypes = {
    history: PropTypes.oneOfType([PropTypes.object]).isRequired,
}
Header.defaultProps = {
    menuWithArrow: false,
    isBackButton: false,
    isSearch: false,
    isCart: false,
    isTransparent: false,
    isDisplayRibbon: true, // it will stop ribbon on top and also app install banner
}

const mapStateToProps = state => ({
    ...state.LoginReducer,
    ...state.MenuReducers,
    initData: state.InitData,
})
export default connect(
    mapStateToProps,
    {
        ...MenuAct,
    },
)(Header)
