import React from "react"
import propTypes from "prop-types"
import { Helmet } from "react-helmet"

import configs, { isT4Fes } from "../../../config"
import { _unescape } from "../tool/util"

import MobileLogo from "../../public/img/mobile-logo.png"

const htmlLang = configs.language.id === "3" ? "es-ES" : "en-US"
const twitterCreator = isT4Fes ? "@Tours4FunES" : "@tours4fun"

const HeadSEOTags = props => (
    <Helmet>
        <html lang={htmlLang} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content={twitterCreator} />
        {props.title && ([
            <title key="0">{_unescape(props.title)}</title>,
            <meta key="1" property="og:title" content={_unescape(props.title)} />,
            <meta key="2" name="twitter:title" content={_unescape(props.title)} />,
        ])}
        {props.description && ([
            <meta key="0" name="description" content={_unescape(props.description)} />,
            <meta key="1" property="og:description" content={_unescape(props.description)} />,
            <meta key="2" name="twitter:description" content={_unescape(props.description)} />,
        ])}
        {props.keywords && ([
            <meta key="0" name="keywords" content={props.keywords} />,
        ])}
        {props.canonical && ([
            <link key="0" rel="canonical" href={props.canonical} />,
            <meta key="1" property="og:url" content={props.canonical} />,
            <meta key="2" name="twitter:url" content={props.canonical} />,
        ])}
        {props.image && ([
            <meta key="0" property="og:image" content={props.image} />,
            <meta key="1" name="twitter:image" content={props.image} />,
        ])}
        <link rel="preload" as="image" href={MobileLogo.toString()} />
    </Helmet>
)

HeadSEOTags.defaultProps = {
    title: "",
    description: "",
    keywords: "",
    canonical: "",
    image: "",
}

HeadSEOTags.propTypes = {
    title: propTypes.string,
    description: propTypes.string,
    keywords: propTypes.string,
    canonical: propTypes.string,
    image: propTypes.string,
}

export default HeadSEOTags
